

export function getUserPosition(silent: boolean = false): Promise<[number, number]> {
    return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(position => {
            resolve([position.coords.latitude, position.coords.longitude]);
        }, e => {
            if(!silent) {
                window.alert(
                    e.code === GeolocationPositionError.PERMISSION_DENIED
                        ? 'La localisation n’est pas disponible, veuillez l’activez dans les paramètres de votre navigateur'
                        : 'La localisation n’est pas disponible sur votre appareil, veuillez essayer sur un autre navigateur'
                );
            }
            reject(e);
        }, { timeout:5000 })
    });
}
