import Alpine from '../alpinejs';
import debounce from "lodash/debounce";
import throttle from "lodash/throttle";

Alpine.directive('sticky', (el, { expression }, { evaluateLater, cleanup }) => {
    const resizeHandler = debounce(() => layout(el), 100);
    const scrollHandler = throttle(() => layout(el), 100);
    layout(el);
    window.addEventListener('resize', resizeHandler);
    document.addEventListener('scroll', scrollHandler);
    cleanup(() => {
        window.removeEventListener('resize', resizeHandler);
        document.addEventListener('scroll', scrollHandler);
    });
});

function layout(el) {
    const top = parseInt(getComputedStyle(el).top);
    let totalHeight = top + el.offsetHeight;
    if(el.className.includes('--header-bottom')) {
        const headerBottom = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--header-bottom'));
        totalHeight = top - headerBottom + el.offsetHeight;
    }
    if(totalHeight > window.innerHeight) {
        el.style.setProperty('position', 'static', 'important');
    } else {
        el.style.setProperty('position', '');
    }
}
