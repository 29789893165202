import { Alpine as LivewireAlpine } from '../../vendor/livewire/livewire/dist/livewire.esm.js';

/**
 * @type {import('alpinejs').Alpine}
 */
const Alpine = LivewireAlpine;

// noinspection JSConstantReassignment
window.Alpine = Alpine;

export default Alpine;
