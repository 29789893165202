import { getUserPosition } from "@/utils/geolocation";


window.storeSelector = function storeSelector() {
    return {
        get userLongitude() {
            return this.$wire.userLongitude;
        },
        get userLatitude() {
            return this.$wire.userLatitude;
        },
        selectedCity: null,
        get $autocomplete() {
            return Alpine.$data(this.$root.querySelector('[data-city-autocomplete]'));
        },
        onOpenStoreSelectorEvent(e) {
            this.$wire.opened = true;
            this.$wire.productId = e.detail?.productId;
            this.$wire.intent = e.detail?.intent;
        },
        getUserPosition: getUserPosition,
        init() {
            this.$watch('$wire.opened', (opened, previousOpened) => {
                if(previousOpened && !opened) {
                    this.$dispatch('store-selector-closed');
                }
            });
        },
    }
}

document.addEventListener('alpine:init', () => {
    Alpine.data('storeSelector', storeSelector);
});
