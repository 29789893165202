import Alpine from 'alpinejs';
import {route} from "ziggy-js";
import {api} from "@/api";

type Params = {
    selected: boolean,
    productId: number,
}

Alpine.data('customerWishlistButton', ({ selected, productId }: Params) => ({
    selected,
    async toggle() {
        if(this.selected) {
            await api.delete(route('customer-wishlist.destroy', { product: productId }));
            this.selected = false;
            this.$dispatch('show-toast', {
                message: `Produit supprimé de <a href="${route('customer-wishlist.index')}">vos favoris</a>`,
            });
        } else {
            await api.post(route('customer-wishlist.store', { product: productId }));
            this.selected = true;
            this.$dispatch('show-toast', {
                message: `Produit ajouté à <a href="${route('customer-wishlist.index')}">vos favoris</a>`,
            });
        }
        this.$dispatch('update-header-customer-wishlist-count');
    },
    init() {
        // @ts-ignore
        window.customerWishlistButton = (params: Params) => this;
    }
}));
