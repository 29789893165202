import Alpine from "alpinejs";
import { getUserPosition } from "@/utils/geolocation";

type Store = { id: number, full_name: string, pos_lat: number, pos_lng: number }

declare module 'alpinejs' {
    interface Magics<T> {
        $wire: {
            /** @see {import('../../../app/Livewire/StoreMap.php')} */
            query: string | null,
            markerStores: Array<Store>,
            searchLatitude?: number,
            searchLongitude?: number,
            userLatitude?: number,
            userLongitude?: number,
            searchStoresNearUserPosition: (lat: number, lng: number) => Promise<void>,
        }
    }
}

Alpine.data('storeMap', ({ mapId }) => {
    let map: google.maps.Map;
    return {
        offsetX: -200,
        map: () => map,
        autocomplete: null as HTMLElement | null,
        getUserPosition: getUserPosition,
        selectedStore: null as Store | null,
        selectedMarker: null as google.maps.marker.AdvancedMarkerElement | null,
        markers: [] as google.maps.marker.AdvancedMarkerElement[],
        get userLatitude(): number|undefined {
            return this.$wire.userLatitude;
        },
        get userLongitude(): number|undefined {
            return this.$wire.userLatitude;
        },
        async updateMarkers() {
            const { Size } = await google.maps.importLibrary('core') as google.maps.CoreLibrary;
            const { InfoWindow } = await google.maps.importLibrary('maps') as google.maps.MapsLibrary;
            const { AdvancedMarkerElement, Marker } = await google.maps.importLibrary('marker') as google.maps.MarkerLibrary;
            const infoWindow = new InfoWindow({
                content: '',
                headerDisabled: true,
                pixelOffset: new Size(0, -8),
                disableAutoPan: true,
            });
            this.markers.forEach(marker => marker.remove());
            this.selectedStore = null;
            this.markers = this.$wire.markerStores.map(store => {
                const marker = new AdvancedMarkerElement({
                    map,
                    position: { lat: store.pos_lat, lng: store.pos_lng },
                    title: store.full_name,
                    content: (document.querySelector('template[data-marker]') as HTMLTemplateElement).content.cloneNode(true),
                    gmpClickable: true,
                });
                marker.addEventListener('mouseover', () => {
                    infoWindow.close();
                    infoWindow.setContent(marker.title);
                    infoWindow.open({ map, shouldFocus: false, anchor: marker });
                });
                marker.addEventListener('mouseout', () => {
                    infoWindow.close();
                });
                marker.addEventListener('gmp-click', () => {
                    this.selectedStore = store;
                    map.panTo(marker.position!);
                    map.setZoom(Math.max(map.getZoom()!, 11));
                    infoWindow.close();
                    if(this.selectedMarker) {
                        this.selectedMarker.content = (document.querySelector('template[data-marker]') as HTMLTemplateElement).content.cloneNode(true);
                    }
                    marker.content = (document.querySelector('template[data-marker-selected]') as HTMLTemplateElement).content.cloneNode(true);
                    this.selectedMarker = marker;
                });
                return marker;
            });
        },
        async fitToMarkers() {
            const { LatLngBounds } = await google.maps.importLibrary('core') as google.maps.CoreLibrary;
            if(this.markers.length > 1) {
                const bounds = new LatLngBounds();
                this.markers.forEach(marker => bounds.extend(marker.position!));
                map.fitBounds(bounds, Math.min(this.$refs.map.offsetHeight * .2, 100));
            } else if(this.markers.length) {
                map.panTo(this.markers[0].position!);
                map.setZoom(11);
            }
        },
        async findStoresNearMe() {
            const [lat, lng] = await getUserPosition();
            await this.$wire.searchStoresNearUserPosition(lat, lng);
            this.autocomplete!.dispatchEvent(new CustomEvent('reverse-geocode', { detail: { lat, lng } }));
        },
        async init() {
            this.autocomplete = this.$el.querySelector('[data-city-autocomplete]');
            this.$nextTick(() => {
                this.autocomplete!.dispatchEvent(new CustomEvent('init-selection', {
                    detail: {
                        query: this.$wire.query,
                        lat: this.$wire.searchLatitude,
                        lng: this.$wire.searchLongitude,
                    }
                }));
            });
            const { Map } = await google.maps.importLibrary('maps') as google.maps.MapsLibrary;
            map = new Map(this.$refs.map as HTMLElement, {
                center: { lat: 46.4, lng: 2.2343779 },
                zoom: window.innerWidth < 1024 ? this.$refs.map.offsetHeight < 250 ? 4 : 5 : 6,
                disableDefaultUI: true,
                // gestureHandling: 'ontouchstart' in window ? 'cooperative' : 'greedy',
                gestureHandling: 'greedy',
                mapId,
            });
            await this.updateMarkers();
            if(this.$wire.searchLatitude && this.$wire.searchLongitude) {
                this.fitToMarkers();
            }
            this.$watch('$wire.markerStores', async () => {
                if(window.innerWidth < 768) {
                    (document.activeElement as HTMLElement)?.blur();
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                }
                await this.updateMarkers();
                if(this.$wire.searchLatitude && this.$wire.searchLongitude) {
                    this.fitToMarkers();
                } else {
                    map.panTo({ lat: 46.4, lng: 2.2343779 });
                    map.setZoom(window.innerWidth < 1024 ? this.$refs.map.offsetHeight < 250 ? 4 : 5 : 6);
                }
            });
            // @ts-ignore
            window.storeMap = () => this;
            // @ts-ignore
            window.map = this.map;
        },
    }
});
