import Alpine from "@/alpinejs";

Alpine.data('cityAutocomplete', window.cityAutocomplete = ({ submitOnSelect }) => ({
    query: '',
    /** @type {import('@p-j/geocodejson-types').GeocodeResponse} */
    response: null,
    /** @param {import('@p-j/geocodejson-types').GeocodeFeature} feature */
    displayValue(feature) {
        return feature?.properties.city;
    },
    /** @type {import('@p-j/geocodejson-types').GeocodeFeature} */
    selectedFeature: null,
    get selectedLatitude() {
        return this.selectedFeature?.geometry.coordinates[1];
    },
    get selectedLongitude() {
        return this.selectedFeature?.geometry.coordinates[0];
    },
    async search() {
        const q = this.query.match(/^\d\d$/) ? this.query+'000' : this.query;
        if(q.length < 3) { this.response = null; return; }
        this.response = await fetch('https://api-adresse.data.gouv.fr/search/?'+new URLSearchParams({
            type: 'municipality',
            autocomplete: '1',
            limit: '5',
            lat: this.$data.userLatitude ?? '',
            lon: this.$data.userLongitude ?? '',
            q
        }))
            .then(r => r.ok ? r.json() : Promise.reject(r))
    },
    async reverseGeocode({ lat, lng } = {}) {
        this.response = await fetch('https://api-adresse.data.gouv.fr/reverse?'+new URLSearchParams({
            lat: lat ?? this.$data.userLatitude ?? '',
            lon: lng ?? this.$data.userLongitude ?? '',
        }))
            .then(r => r.ok ? r.json() : Promise.reject(r));
        this.selectedFeature = this.response?.features?.[0];
    },
    async onSubmit(e) {
        await this.search();
        this.selectedFeature = this.response?.features?.[0];
    },
    clear() {
        this.query = '';
        this.selectedFeature = null;
    },
    /** @see {import('../../js/livewire/store-map.js')} */
    initSelection({ query, lng, lat }) {
        this.query = query;
        this.selectedFeature = {
            properties: {
                city: query,
                initial: true,
            },
            geometry: { coordinates: [lng, lat] }
        }
    },
    init() {
        this.$watch('selectedFeature', () => {
            if(this.selectedFeature && !this.selectedFeature.properties.initial) {
                this.$nextTick(() => {
                    this.$dispatch('selected', {
                        query: this.selectedFeature.properties.city,
                        lat: this.selectedFeature.geometry.coordinates[1],
                        lng: this.selectedFeature.geometry.coordinates[0],
                    });
                    if(submitOnSelect) {
                        this.$el.querySelector('form').submit();
                    }
                })
            }
        });
    },
    autocompleteInput: {
        'x-model': 'query',
        'x-combobox:input': '',
        '@keydown.enter'(e) {
            if(!this.response) {
                this.onSubmit();
            }
        },
        'x-ref': 'autocompleteInput',
    }
}));
