import Alpine from "../alpinejs";

Alpine.data('header', window.header = () => ({
    previousScrollY: window.scrollY,
    anchorY: 0,
    anchorTranslateY: '0',
    previousAnchorTranslateY: '0',
    height: 150,
    locked: false,
    updateAnchorY() {
        if(!this.locked && getComputedStyle(this.$el).transform !== 'none' && window.scrollY >= 0) {
            let dy = scrollY - this.previousScrollY;
            this.anchorY = Math.max(0, Math.min(this.anchorY + dy, this.height));
            this.anchorTranslateY = `${(this.anchorY / this.height) * -100}%`;
        }

        this.previousScrollY = scrollY;
    },
    lockAnchorY(lock) {
        if(!this.locked && lock && getComputedStyle(this.$el).transform !== 'none') {
            this.$el.animate([{ translate: `0 ${this.anchorTranslateY}` }, { translate: `0 0` }], { duration: this.anchorY * 2, easing: 'ease-out', fill: 'forwards' })
            this.anchorY = 0;
            this.anchorTranslateY = '0%';
        }
        this.locked = lock;
        return true;
    },
    updateRootVariables() {
        this.$nextTick(() => {
            document.documentElement.style.setProperty('--header-bottom', `${this.$el.getBoundingClientRect().bottom}px`);
            document.documentElement.style.setProperty('--header-height', `${this.height}px`);
            document.documentElement.style.setProperty('--header-top', `${document.querySelector('#header-top-sentinel').offsetTop}px`);
        });
    },
    initHeader() {
        this.height = this.$el.offsetHeight;
        new ResizeObserver(() => {
            this.height = this.$el.offsetHeight;
            this.updateRootVariables();
        }).observe(this.$el);
    },
}));
